import React from 'react';
import {Link} from 'react-router-dom';
import { useState } from 'react';

export default function Navbar () {


    return (
      <div>
    <header>
    <div class="navbar--left">
    <div class="navbar--logo">
    <Link to='/'><img src="https://legal-tech.me/wp-content/uploads/2022/02/LegalTechME_logo.png" alt=""/> </Link>
    </div>
    </div>
    <div class="navbar--center">
        <div class="navbar--center--content">
            <Link to='/'>Accueil</Link>
            <Link to='/'>Faire des documents</Link>
            <Link to='/contact'>Signer documents</Link>
            <Link to='/contact'>Impôts</Link>
            <Link to='/contact'>Demander un avocat</Link>
            <Link to='/contact'>Contactez-nous</Link>
        </div>
        </div>
    <div class="navbar--right">
        <div class="navbar--right--content">
            <button class="btn__sign__up">Connexion</button>
        </div>
      
    </div>
    </header>
    {/* <nav>
         <label class="logo">CodingNepal</label>
         <ul>
            <li><a class="active" href="#">Home</a></li>
            <li>
               <a href="#">Features
               <i class="fas fa-caret-down"></i>
               </a>
               <ul>
                  <li><a href="#">Python</a></li>
                  <li><a href="#">JQuery</a></li>
                  <li><a href="#">Javascript</a></li>
               </ul>
            </li>
            <li>
               <a href="#">Web Design
               <i class="fas fa-caret-down"></i>
               </a>
               <ul>
                  <li><a href="#">Front End</a></li>
                  <li><a href="#">Back End</a></li>
                  <li>
                     <a href="#">Others
                     <i class="fas fa-caret-right"></i>
                     </a>
                     <ul>
                        <li><a href="#">Links</a></li>
                        <li><a href="#">Works</a></li>
                        <li><a href="#">Status</a></li>
                     </ul>
                  </li>
               </ul>
            </li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Feedback</a></li>
         </ul>
      </nav> */}
  </div>
    );
}

