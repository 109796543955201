import Navbar from '../Navbar';
import 'animate.css';
import Footer from '../Pages/Footer';
import '../App.css';
import {Link} from 'react-router-dom';


export default function Home () {

    return (
        <div>
        <Navbar/>
        <div class="main__part">
        <div class="main__part__content">
            <h1>Vivez en toute confiance</h1>
            <p>Protégez-vous et votre famille légalement</p>
        </div>
        </div>
        <div class="content">
    <h2 class="section-title">Nos documents les plus populaires</h2>

    <div class="topic-item-container">
            <div class="topic-list">
                <dl class="topic-item">
                    <dt class="topic-title">
                            <img class="topic-item-icon" src="https://www.rocketlawyer.com/binaries/content/gallery/badges-and-icons/Icons2022OrangeCategory/icon-category-business+1+%281%29.svg" alt="" height="32" width="32"/>

                        <div class="topic-item-name">
                            <h4>Business and contracts</h4>
                        </div>
                    </dt>

                        <dd class="topic-data"><Link className='data-link' to='/'>Accord de non-divulgation</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Contrat commercial</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Accord d'entrepreneur indépendant</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Accord d'exploitation LLC</Link></dd>
                </dl>
            </div>
            <div class="topic-list">
                <dl class="topic-item">
                    <dt class="topic-title">
                            <img class="topic-item-icon" src="https://www.rocketlawyer.com/binaries/content/gallery/badges-and-icons/Icons2022OrangeCategory/icon-category-realestate+1+%281%29.svg" alt="" height="32" width="32"/>

                        <div class="topic-item-name">
                            <h4>Immobilier</h4>
                        </div>
                    </dt>

                        <dd class="topic-data"><Link className='data-link' to='/'>Contrat de location</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Avis d'expulsion</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Intention d'acheter un bien immobilier</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Acte de renonciation</Link></dd>
                </dl>
            </div>
            <div class="topic-list">
                <dl class="topic-item">
                    <dt class="topic-title">
                            <img class="topic-item-icon" src="https://www.rocketlawyer.com/binaries/content/gallery/badges-and-icons/Icons2022OrangeCategory/icon-category-personal+1+%281%29.svg" alt="" height="32" width="32"/>

                        <div class="topic-item-name">
                           <h4>Familial et personnel</h4>
                        </div>
                    </dt>

                        <dd class="topic-data"><Link className='data-link' to='/'>Dernière volonté et testament</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Testament de vie</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Accord de règlement de divorce</Link></dd>
                        <dd class="topic-data"><Link className='data-link' to='/'>Autorisation de garde d'enfants</Link></dd>
                </dl>
            </div>
    </div>

        <div class="highlighted-topic-button main-button">
            <button class="topic-item-footer--btn">See all documents</button>     
        </div>
      </div>
      <div class="legal-advice-container">
        <div class="content">
            <h2 class="section-title legal-advice-title">Des conseils juridiques abordables</h2>
            <h4 class="section-titles">Obtenez des réponses rapides de vrais avocats, facilement. Posez toute question juridique ou demandez à un avocat sur appel d'examiner votre document.</h4>
                <h4>Rencontrez certains de nos avocats sur appel</h4>
                        <div className='legal-advice--content'>
                            <div className='lawyer__card'>
                                <div className='lawyer__card__header'>
                                    <div className='lawyer__pic'>
                                    <img src={`${process.env.PUBLIC_URL}/3.JPG`} alt=''/>
                                    </div>
                                </div>
                                <div className='lawyer__card__info'>
                                <p>Doumbia Fode</p>
                                <p>LegalTech Lawyer</p>
                                <p>En pratique depuis 2016</p>
                                </div>
                            </div>

                        </div>
                        <div class="legal-advice-button main-button">
                            <Link to='/contact'> <button className='btn__ask__lawyer'>Demandez à un avocat</button></Link>
                            </div>
                        </div>
        </div>

        <div class="easy-legal-container">
            <h2 class="section-title easy-legal-title">Documents juridiques faciles</h2>
            <h4 class="section-titles">
                Créez des documents juridiques pour des centaines de raisons. </h4>
                <div className='easy-legal-main'>
                <div className='easy-legal-main-image'>
                    <img  src="https://www.rocketlawyer.com/binaries/content/gallery/rocketlawyerUS/marketing/rl-document-experience-bill-of-sale2x.png" alt="" />
                </div>
                </div>
                <div class="easy-legal-button main-button">
                        <Link to='/contact'><button className='btn__ask__lawyer'>Faire des documents juridiques</button></Link> 
                </div>
        </div>
      {/* <div class="incorporation-container">
            <div class="content-inner-wrap">
            <div class="content-inner-wrap-img">
                        <div class="customer-image">
                            <img src="https://images.unsplash.com/photo-1605664041952-4a2855d9363b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" alt="" />
                        </div>
                </div>

               <div className='content-inner-wrap--content'>
               <h2 class="section-title incorporation-title">Dynamisez votre entreprise</h2>
                <h3 class="section-desc incorporation-desc">Incorporez rapidement et facilement avec des conseils étape par étape.</h3>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option selected>SARL</option>
                    <option value="SARL">SARL</option>
                    <option value="Non-lucratif">Non-lucratif</option>
                    <option value="C-Corp">C-Corp</option>
                    <option value="S-Corp">S-Corp</option>
                    </select>
               </div>
            </div>
    </div> */}


      <div className='contact__us'>
          <h2>Des questions ? Nous sommes là pour vous aider</h2>
          <p>Parlez à un spécialiste du service client en direct par téléphone, chat ou e-mail.</p>
          <div className='img__contact'>
            <div className='img__contact__box'>
                <img src={`${process.env.PUBLIC_URL}/3.JPG`} alt=''/>
            </div>
          </div>
         <Link to='/contact'> <button className='btn__contact__us'>Contact Us</button></Link>
      </div>
        
        <Footer/>
      </div>
    )
  }
